import { useApp, useHttpClient } from "@/vf"
import { defineStore } from "pinia"
import { ref, watch } from "vue"
import { useOverlayStore } from "."
import { UserInfoOverlay } from "../components/overlays"
import { type SupportedInfo, useShopAppConfig } from "../composables"

export type UserInfos = {
    gender?: "m" | "f" | "d"
    eventAnswer?: "open" | "yes" | "no"
    hasAcknowledgedCanGDialog?: boolean
    etiquetteAccepted?: boolean
}

// info ids that are listed in this array can be dismissed by the user, without being logged out
const nonMandatoryUserInfo: readonly SupportedInfo[] = ["eventAnswer", "acknowledgeCanG"] as const

/**
 * A service that requests user info from the user. Which infos should be requested is defined in the app config.
 * The user is asked for each info one by one, until all infos have been requested. The user can dismiss non-mandatory
 * infos, but not mandatory ones. If a mandatory info is dismissed, the user is logged out. After all infos have been
 * requested, the overlay is closed.
 */
export const useRequestUserInfoStore = defineStore("request-user-info", () => {
    const { appConfig, refreshAppConfig } = useShopAppConfig()
    const { post } = useHttpClient()
    const { logout } = useApp()
    const overlayStore = useOverlayStore()
    const dismissedNonMandatoryUserInfo = ref(false)

    const requestInfos = ref<SupportedInfo[]>([])

    // which info is currently requested
    const requestInfo = ref<SupportedInfo>()

    watch(appConfig, newAppConfig => updateRequestInfo(newAppConfig.requestUserInfos ?? []), { immediate: true })

    function updateRequestInfo(newRequestUserInfos: SupportedInfo[]) {
        if (dismissedNonMandatoryUserInfo.value) {
            // when the user has dismissed the non-mandatory info, don't request it again
            newRequestUserInfos = newRequestUserInfos.filter(info => !nonMandatoryUserInfo.includes(info))
        }

        // request the next info
        requestInfos.value = newRequestUserInfos
        requestInfo.value = requestInfos.value[0] ?? undefined

        if (requestInfo.value === undefined) {
            // everything has been answered
            return
        }

        overlayStore.open(UserInfoOverlay)
    }

    async function updateProfile(infos: UserInfos) {
        await post("/my-account/update-profile", infos)

        // remove the requested info from the list and request the next one
        updateRequestInfo(requestInfos.value.filter(info => info !== requestInfo.value))

        if (requestInfo.value === undefined) {
            // close the overlay if there are no more infos to request
            overlayStore.close()
        }

        await refreshAppConfig()
    }

    async function dismiss() {
        const isMandatory = requestInfo.value && !nonMandatoryUserInfo.includes(requestInfo.value)

        if (isMandatory) {
            logout()
            overlayStore.close()
            return
        }

        dismissedNonMandatoryUserInfo.value = true
        overlayStore.close()
    }

    return {
        updateProfile,
        requestInfo,
        dismiss,
    } as const
})
