<script setup lang="ts">
import { computed, ref } from "vue"
import { useShopAppConfig } from "@/shop/composables"

const { appConfig } = useShopAppConfig()

const currentParagraph = ref(0)
const totalParagraphs = 4
const isConfirmed = ref(false)

function goToNextParagraph() {
    if (currentParagraph.value < totalParagraphs - 1) {
        currentParagraph.value++
    }
}

const isLastParagraph = computed(() => currentParagraph.value === totalParagraphs - 1)
const emit = defineEmits(["accepted"])

function confirmEtiquette() {
    emit("accepted")
}
</script>

<template>
    <div>
        <!-- Paragraph 1 -->
        <template v-if="currentParagraph === 0">
            <p>Moin {{ appConfig.user.firstName }}!</p>
            <p>
                Wir bemühen uns täglich darum, unseren Kunden und Patienten nicht nur ein breites Angebot unserer
                lagernden Produkte, sondern auch ein großes Serviceangebot im Rahmen unserer Dienstleistungen zu bieten.
                Leider haben wir in den letzten Monaten eine <b>massiv zunehmende Verrohung</b> im Umgang mit unseren
                Mitarbeitern feststellen müssen. Während wir sachlich vorgetragene Kritik und Verbesserungsvorschläge
                gerne entgegennehmen und dazu auch Stellung beziehen, so sind Beleidigungen und sogar Gewaltandrohungen
                mittlerweile keine Seltenheit mehr. Den traurigen Höhepunkt bildeten in dieser Hinsicht die Feiertage,
                die dem Jahreswechsel 2024/25 vorangingen. Traditionell ist Hamburg als weltoffene Metropole geprägt von
                einer multikulturellen und liberalen Gesellschaft, deren gemeinsamer Werte
                <b>gegenseitiger Respekt</b> und Toleranz voranstehen. Um es klar zu sagen: Für die überwiegende
                Mehrheit unserer Kunden und Patienten ist die Einhaltung grundlegender Umgangsformen eine
                Selbstverständlichkeit. Bitte nimm dir dennoch einmalig einen kurzen Moment, unsere folgenden
                „Benimmregeln“ zu lesen, bevor du mit deiner nächsten Bestellung fortfährst.
            </p>
        </template>

        <!-- Paragraph 2 -->
        <template v-if="currentParagraph === 1">
            <p><b>Allgemeine Kontaktaufnahme:</b></p>
            <p>
                Wir geben dir bereits bei Bestellung eine ungefähre Angabe, über die
                <b>aktuell zu erwartende Bearbeitungsdauer.</b> Wir unterstützen die Kommunikation durch Status-Updates
                per Mail. Bitte lies dir diese <b>erst durch, bevor</b> du erneut Kontakt zu uns aufnimmst und die
                angegebene Bearbeitungszeit <b>noch nicht</b> überschritten ist. Bitte beschränke deine Kontaktaufnahme
                auf <b>dringende</b> Anliegen. Jegliche Unterbrechung unserer Abläufe verzögert nicht nur deine eigene
                Versorgung, sondern auch die aller anderen Patienten.
                <b
                    >Wer daher regelmäßig wiederholt oder penetrant unsere Abläufe unterbricht, wird im Interesse aller
                    anderen Patienten von einer zukünftigen Versorgung durch uns ausgeschlossen.</b
                >
            </p>
        </template>

        <!-- Paragraph 3 -->
        <template v-if="currentParagraph === 2">
            <p><b>Notfälle, Akutversorgung, Urlaubsantritt:</b></p>
            <p>
                Cannabispräparate sind <b>keine</b> Notfallmedikamente, sondern dienen überwiegend der Linderung
                chronischer Beschwerden. Es sollte daher verständlich sein, dass <b>jeder Patient gleichermaßen</b> ein
                Interesse an einer Versorgung hat, weshalb die Versorgung ausschließlich <b>der Reihe nach</b> geht. Wir
                bemühen uns sehr im begründeten Einzelfall auch kurzfristiger helfen zu können, aber einige wenige
                Patienten, geben bei nahezu jeder Bestellung einen Notfall an.
                <b
                    >Das untergräbt die Solidarität und kann daher nicht von uns geleistet werden. Benötigst du deine
                    Bestellung schneller, als wir sie dir anbieten können, müssen wir die Bestellung ablehnen.</b
                >
            </p>
        </template>

        <!-- Paragraph 4 -->
        <template v-if="currentParagraph === 3">
            <p><b>Beleidigungen, Drohungen, Sachbeschädigung:</b></p>
            <p>
                Manche Patienten haben aufgrund ihrer Erkrankung einen hohen Leidensdruck. Das ist uns bewusst und Teil
                unserer täglichen Arbeit damit umgehen zu können. Gewissen Unmutsäußerungen können wir daher
                professionell begegnen. Eine Grenze wird jedoch überschritten bei persönlichen Beleidigungen und der
                Androhung oder womöglich sogar der Ausübung von Gewalt oder Sachbeschädigung.
                <b
                    >In aller Deutlichkeit: Für rassistische, frauenfeindliche oder andere menschenverachtende
                    Beleidigungen, Androhung oder Ausübung von Gewalt und Sachbeschädigung gilt bei uns eine
                    Null-Toleranz-Politik. Derartiges Verhalten führt zum sofortigen Ausschluss der weiteren Versorgung,
                    Hausverbot und wird direkt zur Anzeige gebracht.</b
                >
            </p>
        </template>

        <!-- Navigation Buttons -->
        <div v-if="!isLastParagraph" class="mt-5">
            <button type="button" class="btn btn-primary-filled" @click="goToNextParagraph">
                Gelesen ({{ currentParagraph + 1 }}/{{ totalParagraphs }})
            </button>
        </div>

        <!-- Confirmation Section -->
        <div v-else>
            <div class="form-check mt-5 mb-3">
                <input id="confirmCheckbox" v-model="isConfirmed" type="checkbox" class="mr-1" />
                <label for="confirmCheckbox" class="form-check-label">Ich habe alles gelesen und verstanden</label>
            </div>
            <button class="btn btn-primary-filled" :disabled="!isConfirmed" @click="confirmEtiquette">Schließen</button>
        </div>
    </div>
</template>
