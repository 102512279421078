<script setup lang="ts">
import { useDialogs } from "@/vf"
import { UseMouse, vElementHover } from "@vueuse/components"
import { useScroll } from "@vueuse/core"
import { ref, useSlots } from "vue"
import TerpeneExplanationDialog from "@/shop/pages/article/TerpeneExplanationDialog.vue"

const isHovering = ref(false)
const { x: scrollX, y: scrollY } = useScroll(document)
const slots = useSlots()

const dialogs = useDialogs()

const props = withDefaults(
    defineProps<{
        text: string
    }>(),
    {},
)

function openDialog() {
    dialogs.open({
        component: TerpeneExplanationDialog,
        props: { text: props.text },
    })
    console.log(slots.default)
}
</script>

<template>
    <div v-element-hover="hovering => (isHovering = hovering)" class="d-inline">
        <button type="button" class="border-0 bg-transparent" style="cursor: default" @click="openDialog()">
            <i class="fa fw-fa fa-circle-info text-primary"></i>
        </button>

        <UseMouse v-slot="{ x, y }">
            <div
                v-if="isHovering"
                class="popover popover-help"
                :style="{ position: 'fixed', top: `${y - scrollY}px`, left: `${x - scrollX}px` }"
            >
                <div class="popover-body" v-html="props.text" />
            </div>
        </UseMouse>
    </div>
</template>
